@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
body {
  margin: 0;
  line-height: normal;
}

:root {
  /* fonts */
  --font-poppins: Poppins;
  --font-roboto: Roboto;
  /* font sizes */
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-xs: 12px;
  --font-size-smi: 13px;
  --font-size-2xs: 11px;
  /* Colors */
  --color-white: #fff;
  --color-black: #000;
  --color-gray-100: #868686;
  --m3-sys-light-outline: #79747e;
  --m3-sys-light-on-secondary-container: #1d192b;
  --color-gray-200: rgba(255, 255, 255, 0.53);
  --color-gray-300: rgba(0, 0, 0, 0.89);
  --color-dimgray-100: #676767;
  --color-dimgray-200: #656565;
  --color-dimgray-300: #646464;
  --color-dimgray-400: #615f5f;
  --color-dimgray-500: #525252;
  --color-dimgray-600: #4d4d4d;
  --color-dimgray-700: #4c4c4c;
  --color-dimgray-800: #564636;
  --color-mediumslateblue: #0060ff;
  --color-mediumpurple: #765ab8;
  --color-slateblue: #6a4dae;
  --color-lightskyblue: #9ebefb;
  --color-darkslategray-100: #425a42;
  --color-darkslategray-200: #2f2f2f;
  --color-darkslategray-300: #2d2d2d;
  --color-darkslategray-400: #292c2d;
  --color-lightgray: #d1d1d1;
  --color-forestgreen: #29a52b;
  --color-darkseagreen: #90d392;
  --color-seagreen: #016f54;
  --color-orange: #feb101;
  --color-lightseagreen: #01b7ab;
  --color-darkslateblue: #1658af;
  --color-purple: #8d3b9a;
  --color-gainsboro: #d9d9d9;
  --color-firebrick: #ad0028;
  /* Gaps */
  --gap-5xs: 8px;
  --gap-6xs: 7px;
  /* Paddings */
  --padding-9xs: 4px;
  --padding-5xl: 24px;
  --padding-12xs: 1px;
  --padding-3xs: 10px;
  --padding-11xs: 2px;
  --padding-5xs: 8px;
  --padding-10xs-5: 2.5px;
  --padding-8xs: 5px;
  --padding-xs: 12px;
  --padding-mini: 15px;
  --padding-4xs: 9px;
  --padding-3xl: 22px;
  --padding-mid: 17px;
  /* border radiuses */
  --br-9xs: 4px;
  --br-8xs: 5px;
  --br-base: 16px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
  text-decoration: none !important;
}

input[type=checkbox]:focus {
  outline: none !important;
  outline-color: none !important;
  outline-style: none;
}
