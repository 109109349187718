.notificationBar {
  position: fixed;
  z-index: 100000;
  min-height: 4vw;
  font-size: 2vw;
  padding: 5px;
  top: 10px;
  left: 20%;
  right: 20%;
  transition: 500ms;
  margin-top: -1000px;
  transition-property: all;
}

.notificationOpen {
  margin-top: 0;
}

.notificationClose {
  margin-top: -1000px;
}

.alertSuccess {
  color: white;
  background-color: #24b12f;
}

.alertDanger {
  color: white;
  background-color: #e42323;
}

.cardBorder {
  border: 5px solid black;
}

.cardBottomBorder {
  border-bottom: 5px solid black;
}
