.popupFlow {
  z-index: 10200 !important;
}

.modalDialog {
  font-display: swap;
}

.modalDialogTop {
  display: flex;
  align-items: center;
  min-height: calc(100% - 25rem);
}

.modalDialogCentered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 5rem);
}

.customWidth {
  max-width: 100%;
  margin: 1rem;
}

@media (min-width: 1920px) {
  .modalDialog {
    max-width: 83vw !important;
    margin: 0.5rem auto;
  }
}
@media (min-width: 602px) {
  .modalDialog {
    max-width: 650px;
    margin: 0.5rem auto;
  }
}
@media (min-width: 1020px) {
  .modalDialog {
    max-width: 800px;
    margin: 0.5rem auto;
  }
}
@media (max-width: 600px) {
  .modalDialog {
    max-width: 520px;
    margin: 0.5rem auto;
  }
}
.editCallCenterBackground {
  background-color: var(--pop-up-bg, #2F2F2F);
}
