.screenSaver {
  z-index: 99999;
}

.charging {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999;
}
